import * as React from "react";
import Layout from "../components/layout";
import cookieContent from "../types/Cookie";

const Cookie = () => {
  return (
    <Layout pageTitle="Home Page">
      <div className="mx-auto max-w-5xl py-12 text-gray-800 px-4">
        <div className="font-medium text-5xl">{cookieContent.title}</div>
        <div className="font-medium text-xl text-gray-500 mb-12">{cookieContent.subTitle}</div>
        {cookieContent.steps.map((step, stepIdx) => (
          <>
            {step.type === "p" ? (
              <p key={stepIdx} className="my-4 sm:text-justify">
                {step.text}
              </p>
            ) : step.type === "h1" ? (
              <h1 key={stepIdx} className="mt-8 text-3xl">
                {step.text}
              </h1>
            ) : step.type === "li" ? (
              <p key={stepIdx} className="my-0.5 sm:text-justify">
                {step.text}
              </p>
            ) : step.type === "ul" ? (
              <ol className="list-decimal ml-10">
                {step.items.map((item, itemIdx) => (
                  <li key={itemIdx} className="sm:text-justify">
                    {item.text}
                  </li>
                ))}
              </ol>
            ) : (
              <>
                <div>{step.text}</div>
              </>
            )}
          </>
        ))}
      </div>
    </Layout>
  );
};

export default Cookie;

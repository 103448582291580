const cookieSteps = [
  {
    type: "p",
    text: "Seneka Yazılım Donanım Bilişim Ticaret Taahhüt Ve San. Ltd. Şti. olarak, web sitemizin kullanımını kolaylaştırmak ve kişiselleştirmek, sizlere daha iyi, hızlı ve güvenli bir deneyim sağlamak, ihtiyaç ve ilgilerinizi anlamak için çerezler ve yerel saklama teknolojileri kullanmaktayız. Bu sayfada, anılan teknolojilerin niçin kullanıldıklarını, bunları kontrol etmeyi veya tercihiniz bu yöndeyse silmeyi, anlamanıza yardımcı olmak istiyoruz.",
  },
  {
    type: "h1",
    text: "Çerez Nedir?",
  },
  {
    type: "p",
    text: "Günümüzde neredeyse bütün web siteleri çerezler kullanmaktadır. Çerezler, bilgisayarınız ya da mobil cihazınız üzerinden ziyaret ettiğiniz web siteleri tarafından cihazınıza (örneğin; bilgisayar veya cep telefonu) veya ağ sunucusuna depolanan küçük metin dosyalarıdır. Çerezler, bir web sitesini ilk ziyaretiniz sırasında web tarayıcınız aracılığıyla cihazınıza depolanabilirler. Aynı siteyi aynı cihazla tekrar ziyaret ettiğinizde tarayıcınız cihazınızda site adına kayıtlı bir çerez olup olmadığını kontrol eder. Eğer kayıt var ise, kaydın içindeki veriyi ziyaret etmekte olduğunuz web sitesine iletir. Bu sayede web sitesi, sizin siteyi daha önce ziyaret ettiğinizi anlar ve size iletilecek içeriği de ona göre tayin eder.",
  },
  {
    type: "h1",
    text: "Çerezlerde Hangi Tür Veriler İşlenir?",
  },
  {
    type: "p",
    text: "Web sitelerinde çoğunlukla yer alan çerezlerde, türüne bağlı olarak, siteyi ziyaret ettiğiniz cihazdaki (pc veya mobil) tarama ve kullanım tercihlerinize ilişkin veriler toplanmaktadır. Bu veriler, eriştiğiniz sayfalar, incelediğiniz uygulama ve hizmet, tercih ettiğiniz dil seçeneği, kullanım ayarları ve diğer tercihlerinize dair bilgileri kapsamaktadır.",
  },
  {
    type: "h1",
    text: "Çerezler Neden Kullanılır?",
  },
  {
    type: "p",
    text: "Çerezler; daha önceki ziyaretlerinizde kullandığınız tercih (dil, kullanım vb.) ve diğer ayarları içeren bu küçük metin dosyaları sayesinde web sitesi tarafından hatırlanmasını sağlayarak, deneyiminizi iyileştirmek için uygulama ve hizmetlerimizi geliştirmek için yardımcı olur. Böylece sonraki ziyaretlerinizin çok daha kullanıcı dostu ve kişiselleştirilmiş bir deneyim sunmak amacı ile kullanılır.",
  },
  {
    type: "p",
    text: "İnternet sitemizde çerez kullanımının başlıca amaçları",
  },
  {
    type: "ul",
    items: [
      { text: "İnternet sitemizin performansını arttırmak," },
      { text: "Sizlere sunulan uygulama ve hizmetleri sürekli geliştirmek ve deneyimi kolaylaştırmak," },
      { text: "Onaylarım Uygulaması ve hizmetlerimiz üzerinde yeni servis ve özellikler sunmak; bu servis ve özellikleri tercihlerinize göre kişiselleştirmek," },
      { text: "Sizin ve şirketimizin hukuki ve ticari güvenliğini sağlamak," },
      { text: "Uygulama ve hizmetlerimiz üzerinden sahte işlemlerin gerçekleştirilmesini önlemek," },
      {
        text: "5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun ve İnternet Ortamında Yapılan Yayınların Düzenlenmesine Dair Usul ve Esaslar Hakkında Yönetmelik’ten kaynaklananlar başta olmak üzere, kanuni ve sözleşmesel yükümlülüklerini yerine getirmek.",
      },
    ],
  },
  {
    type: "h1",
    text: "Çerezleri Kontrol Etme ve Silme",
  },
  {
    type: "p",
    text: "Çoğu tarayıcı çerezleri otomatik olarak kabul eder. Ancak çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da çerezleri engellemek veya silmek için web tarayıcınızın ayarlarını değiştirmeniz yeterlidir. Birçok tarayıcı çerezleri kontrol edebilmeniz için size çerezleri kabul etme veya reddetme, yalnızca belirli türdeki çerezleri kabul etme ya da bir web sitesi cihazınıza çerez depolamayı talep ettiğinde tarayıcı tarafından uyarılma seçeneği sunar. Aynı zamanda daha önce tarayıcınıza kaydedilmiş çerezlerin silinmesi de mümkündür. Çerezleri kontrol edilmesine veya silinmesine ilişkin işlemler kullandığınız tarayıcıya göre değişebilmektedir. Çerezleri reddettiğiniz takdirde sitemizdeki bazı özelliklerin ve hizmetlerin düzgün çalışamayabileceğini, sitemizin kişiselleştirilemeyebileceğini ve sizin deneyiminize göre özelleştirilemeyeceğini lütfen unutmayınız.",
  },
  {
    type: "p",
    text: "Seneka Yazılım Donanım Bilişim Ticaret Taahhüt Ve San. Ltd. Şti. Tarafından Kullanılan Çerezler",
  },
  {
    type: "p",
    text: "Web sitemizde kullanılan hiçbir çerez, kimliğinizin saptanmasına yarayacak verileri işlememektedir. Aşağıda web sitemizde kullanılmakta olan çerez türleri ve bu çerezlerin hangi amaçla kullanıldıkları detaylı bir şekilde ortaya konulmuştur.",
  },
  {
    type: "h1",
    text: "Kategori 1- Gerekli Çerezler",
  },
  {
    type: "p",
    text: "Bu çerezler, web sitesini dolaşmanıza ve web sitesinin güvenli alanlarına erişmenin sağlanması ve izinlerin ortaya konması için önemlidir. Web sitesinin düzgün bir şekilde çalışması için gerekli olan çerezlerdir. Platformun düzgün bir şekilde yönetilebilmesi, kullanıcı hesaplarının oluşturulup kullanıcıların giriş yapabilmesi, sahte işlemlerin önlenmesi için zorunlu çerezler kullanılır. Bu çerezlerin olmaması durumunda web sitesi düzgün çalışmaz.",
  },
  {
    type: "h1",
    text: "Kategori 2- İşlevsel Çerezler",
  },
  {
    type: "p",
    text: "Bu çerezler, web sitesindeki ziyaretinizi kolaylaştırmak ve site üzerindeki deneyiminizi geliştirmek için kullanılan çerezlerdir. Bu çerezler web sitesine yaptığınız bir önceki ziyareti hatırlayarak içeriklere rahatça erişmenizi sağlar.",
  },
  {
    type: "h1",
    text: "Kategori 3- Analitik Çerezler",
  },
  {
    type: "p",
    text: "Bu çerezler, hangi hizmet ve sayfaların daha fazla ilgi çektiğini, hangi kaynakların daha çok görüntülendiğini görmemize yarayan, web sitelerimizdeki trafiği görerek bu trafiğe uygun hizmet sağlamamızı sağlayan veriler içerir. Bu nitelikte kullanılan çerezler bilgiyi anonim olarak depolar.",
  },
  {
    type: "h1",
    text: "Kategori 4- Hedefleme / Reklam Çerezleri",
  },
  {
    type: "p",
    text: "Bu çerezler, ilgi alanınıza yakın olan içerikleri tespit etmemizi ve sunmamızı sağlayan çerezlerdir. Sizi tanıyabilmemiz, ilgi alanlarınıza göre reklamlar sunabilmek adına web sitemize ve mobil sitemize, reklam verdiğimiz diğer web sitelerine üçüncü taraf reklam çerezleri yerleştirilebilir. Bu çerezler ayrıca reklam ve kampanya verimliliğini ve reklam sayısını ölçmek için kullanılmaktadır.",
  },
  {
    type: "h1",
    text: "Kategori 5- Google Analytics ve Google Ads (Adwords) Çerezleri",
  },
  {
    type: "p",
    text: "Bu çerezler kullanıcıların web sitesini nasıl kullandığını analiz eden bir web analiz aracıdır. Anonim olan bilgilerin yanı sıra web sitesi kullanımınızla ilgili kişisel veri niteliğindeki bilgiler (İsim, adres, telefon numarası, e-mail adresi, IP adresi) tarayıcınız tarafından Google’a gönderilir ve Google tarafından kaydedilir.",
  },
  {
    type: "h1",
    text: "Kategori 6- Facebook Pixel Çerezleri",
  },
  {
    type: "p",
    text: "Bu çerezler, daha iyi bir kullanıcı deneyimi sunmak ve ilgi alanınıza giren reklamların size ulaşmasını ve ölçümlenmesini sağlamak; Facebook platformundaki sosyal medya kampanyalarımızın verimliliğini ölçmek amacıyla Facebook Pixel izleme hizmetini kullanmaktayız.",
  },
  {
    type: "p",
    text: "Üst bölümde aktarılan çerezler ziyaretçilerimizin web sitesini nasıl kullandıkları hakkında bilgi toplamaktadır. Örneğin, hangi ziyaretçilerin en sık gidecekleri sayfaları ve web sayfalarından hata mesajları alıp almadıklarını bilmemizi sağlar. Bu çerezler, bir ziyaretçiyi tanımlayan bilgileri değil anonim olarak bilgi toplama işlemi yapmaktadır.",
  },
  {
    type: "p",
    text: "Kullanıcının bu siteyi ziyarete devam etmesi, çerez kullanımına ve kişisel verilerinin işbu politika kapsamında işlenmesine onay verdiği anlamına gelmektedir.",
  },
];

const cookieContent = {
  title: "ÇEREZ  POLİTİKASI",
  subTitle: "",
  steps: cookieSteps,
};

export default cookieContent;